import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import axios from 'axios'
import dayjs from 'dayjs'


axios.defaults.baseURL = 'https://openapi.wherewhere.cloud/v1/'; // 设置基础的接口地址

const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
app.use(ElementPlus)
createApp(App).mount('#app')
