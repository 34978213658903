<template>
  <div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="Day" name="first">
        <div class="avg_response_time">{{ avg_response_time }}</div>
      </el-tab-pane>
      <el-tab-pane label="Week" name="second">
        <div class="avg_response_time">{{ avg_response_time }}</div>
      </el-tab-pane>
      <el-tab-pane label="Month" name="third">
        <div class="avg_response_time">{{ avg_response_time }}</div>
      </el-tab-pane>
    </el-tabs>
    <div class="echarts-box">
      <div id="myEcharts" :style="{ width: '90vw', height: '95vh' }"></div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import * as echarts from "echarts";
import { onMounted } from "vue";
// 导入并注册这两个特定组件
import { ElTabs, ElTabPane } from "element-plus";
const { ref } = require("vue");
const dayjs = require("dayjs");
const activeName = ref("first");
const avg_response_time = ref(0);
const handleClick = async (tab) => {
  const chart = echarts.init(document.getElementById("myEcharts"));
  if (tab.props.name === "first") {
    let currentDate = new Date();
    let hour = currentDate.getHours();
    let previousDayHour = (hour - 24 + 1) % 24;

    // 获取当前时间分钟数并向下取整至 5 的倍数
    let minutes = Math.floor(currentDate.getMinutes() / 5) * 5;

    // 设置时间范围的起始时间和结束时间
    let startTime = new Date();
    startTime.setHours(previousDayHour);
    startTime.setMinutes(minutes);

    let endTime = new Date();
    endTime.setHours(hour);
    endTime.setMinutes(minutes);

    // startTime转换成时间戳
    startTime = Math.floor(startTime.getTime() / 1000);
    // endTime转换成时间戳
    endTime = Math.floor(endTime.getTime() / 1000);
    let minuteDuration = 5;

    try {
      // 调用获取 Echarts 数据的函数，并传递起始时间和结束时间
      const data = await getEchartsData(startTime, endTime, minuteDuration);
      let metrics_list = data.data.metrics_list;
      console.log(data.data.avg_response_time);
      avg_response_time.value = data.data.avg_response_time;
      const echartsData = metrics_list.map(({ timestamp, response_time }) => ({
        x: dayjs(timestamp * 1000).format("MM-DD HH:mm"),
        y: response_time,
      }));
      // 初始化option对象
      let option = {
        // Make gradient line here
        visualMap: [],
        title: [],
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            data: echartsData.map((item) => item.x),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
            },
            axisLabel: {
              formatter: function (value, index) {
                // 每隔3小时显示一次
                if (index % 2 === 0) {
                  return value;
                } else {
                  return "";
                }
              },
            },
            boundaryGap: false, // 设置为false，使得x轴可以包含所有数据
          },
        ],
        yAxis: [
          {
            position: "right",
          },
        ],
        grid: [
          {
            bottom: "60%",
            containLabel: true, // 设置为true，使得x轴的宽度可以容纳所有数据
          },
        ],
        series: [
          {
            type: "line",
            showSymbol: false,
            data: echartsData.map((item) => item.y),
          },
        ],
      };

      chart.setOption(option);
    } catch (error) {
      console.error(error);
    }
  } else if (tab.props.name === "second") {
    // 获取当前时间, 并向前推7天
    let currentDate = new Date();
    let previousWeekDate = new Date();
    previousWeekDate.setDate(currentDate.getDate() - 7);
    // 开始时间为前7天的当前时间,时间点为0点或12点，如果当前时间在接近0点，则取0点，否则取12点
    // 将当前时间设置为凌晨 0 点和中午 12 点的时间戳
    const midnightTime = new Date(
      previousWeekDate.getFullYear(),
      previousWeekDate.getMonth(),
      previousWeekDate.getDate(),
      0,
      0,
      0
    ).getTime();

    const noonTime = new Date(
      previousWeekDate.getFullYear(),
      previousWeekDate.getMonth(),
      previousWeekDate.getDate(),
      12,
      0,
      0
    ).getTime();

    // 判断当前时间距离 0 点和 12 点哪个更近
    let startTime;
    if (
      Math.abs(currentDate.getTime() - midnightTime) <
      Math.abs(currentDate.getTime() - noonTime)
    ) {
      // 当前时间更接近 0 点
      startTime = Math.floor(midnightTime / 1000); // 转换为秒级的时间戳
    } else {
      // 当前时间更接近 12 点
      startTime = Math.floor(noonTime / 1000); // 转换为秒级的时间戳
    }

    const endTime = Math.floor(currentDate.getTime() / 1000); // 当前时间转换为秒级的时间戳

    let minuteDuration = 30;
    try {
      // 调用获取 Echarts 数据的函数，并传递起始时间和结束时间
      const data = await getEchartsData(startTime, endTime, minuteDuration);
      let metrics_list = data.data.metrics_list;
      avg_response_time.value = data.data.avg_response_time;
      const echartsData = metrics_list.map(({ timestamp, response_time }) => ({
        x: dayjs(timestamp * 1000).format("MM-DD HH:mm"),
        y: response_time,
      }));
      // 初始化option对象
      let option = {
        // Make gradient line here
        visualMap: [],
        title: [],
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            data: echartsData.map((item) => item.x),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
            },
            axisLabel: {
              formatter: function (value, index) {
                // 每隔12小时显示一次
                if (index % 2 === 0) {
                  return value;
                } else {
                  return "";
                }
              },
            },
            boundaryGap: false, // 设置为false，使得x轴可以包含所有数据
          },
        ],
        yAxis: [
          {
            position: "right",
          },
        ],
        grid: [
          {
            bottom: "60%",
            containLabel: true, // 设置为true，使得x轴的宽度可以容纳所有数据
          },
        ],
        series: [
          {
            type: "line",
            showSymbol: false,
            data: echartsData.map((item) => item.y),
          },
        ],
      };

      chart.setOption(option);
    } catch (error) {
      console.error(error);
    }
  } else if (tab.props.name === "third") {
    // 获取当前时间, 并向前推30天
    let currentDate = new Date();
    let previousMonthDate = new Date();
    previousMonthDate.setDate(currentDate.getDate() - 30);
    // 获取当前时间距离最近的偶数时间点
    let hour = currentDate.getHours();
    let previousMonthHour = hour - (hour % 2);
    // 开始时间为前30天的当前时间偶数时间点
    let startTime = new Date();
    startTime.setDate(previousMonthDate.getDate());
    startTime.setMonth(previousMonthDate.getMonth());
    startTime.setHours(previousMonthHour);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime = Math.floor(startTime.getTime() / 1000); // 转换为秒级的时间戳
    // 结束时间为当前时间偶数时间点
    let endTime = new Date();
    endTime.setHours(previousMonthHour);
    endTime.setMinutes(0);
    endTime.setSeconds(0);
    endTime = Math.floor(endTime.getTime() / 1000); // 转换为秒级的时间戳
    let minuteDuration = 120;
    try {
      // 调用获取 Echarts 数据的函数，并传递起始时间和结束时间
      const data = await getEchartsData(startTime, endTime, minuteDuration);
      let metrics_list = data.data.metrics_list;
      avg_response_time.value = data.data.avg_response_time;
      const echartsData = metrics_list.map(({ timestamp, response_time }) => ({
        x: dayjs(timestamp * 1000).format("MM-DD HH:mm"),
        y: response_time,
      }));
      // 初始化option对象
      let option = {
        // Make gradient line here
        visualMap: [],
        title: [],
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            data: echartsData.map((item) => item.x),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
            },
            axisLabel: {
            },
            boundaryGap: false, // 设置为false，使得x轴可以包含所有数据
          },
        ],
        yAxis: [
          {
            position: "right",
          },
        ],
        grid: [
          {
            bottom: "60%",
            containLabel: true, // 设置为true，使得x轴的宽度可以容纳所有数据
          },
        ],
        series: [
          {
            type: "line",
            showSymbol: false,
            data: echartsData.map((item) => item.y),
          },
        ],
      };

      chart.setOption(option);
    } catch (error) {
      console.error(error);
    }
  }
};

//声明周期函数，自动执行初始化
onMounted(async () => {
  // 点击first tab
  handleClick({ props: { name: "first" } });
});
// 调用接口
async function getEchartsData(startTime, endTime, minute_duration) {
  try {
    const response = await axios.get("/metrics", {
      params: {
        start_time: startTime,
        end_time: endTime,
        minute_duration: minute_duration,
      },
    }); // 替换为你要调用的接口路径
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
</script>

<style scoped>
.chart {
  box-sizing: border-box;
  height: 400px;
  width: 80%;
  margin: 0 auto;
}

.demo-tabs :deep(.el-tabs__nav) {
  float: right;
}

.demo-tabs :deep(.el-tabs__nav-scroll) {
  margin-right: 25%;
}

.demo-tabs :deep(.el-tabs__nav-wrap::after) {
  left: 60%;
  width: 15%;
}

.avg_response_time {
  width: 50%;
  text-align: right;
  margin: 0 auto;
  padding-top: 20px;
  font-size: 24px;
  color: #aaaaaa;
}
</style>
